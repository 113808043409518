import * as yup from 'yup';

const SUPPORTED_FORMATS = ['application/pdf', 'image/jpeg', 'image/png'];
const FILE_SIZE = 351 * 1024; // 1MB

const Schema = yup.object({
    merchentType: yup.string().required("Merchent Type is required!"),
    name: yup.string().required("Name is required!"),
    address: yup.string().required("Address is required!"),
    city: yup.string().required("City is required!"),
    pincode: yup.string().required("Pincode is required!"),
    state: yup.string().required("State is required!"),
    country: yup.string().required("Country is required!"),
    contactName: yup.string().required("Contact Name is required!"),
    mobile: yup.string().required("Mobile is required!").matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    email: yup.string().required("Email is required!").email("Email must be a valid email address"),
    // pan: yup.string().required("PAN is required!"),
    gst: yup.string().when('merchentType', {
      is: 'business',
      then: yup.string().required('GST Certificate is required for businesses').matches(/^[0-9A-Z]{15}$/, "GST Certificate must be exactly 15 characters"),
      otherwise: yup.string(),
    }),
    // adhaar: yup.string().when('merchentType', {
    //     is: 'individual',
    //     then: yup.string().required('Adhaar is required for individuals').matches(/^[0-9]{12}$/, "Adhaar must be exactly 12 digits"),
    //     otherwise: yup.string(),
    //   }),
    pan: yup
    .mixed()
    .required('PAN is required!')
    .test(
      'fileSize',
      'File size is too large Max 350kb',
      value => value && value.size <= FILE_SIZE
    )
    .test(
      'fileFormat',
      'Unsupported file format',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
    // gst: yup.mixed().when('merchentType', {
    //   is: 'business',
    //   then: yup
    //     .mixed()
    //     .required('GST is required for business')
    //     .test(
    //       'fileSize',
    //       'File size is too large',
    //       value => value && value.size <= FILE_SIZE
    //     )
    //     .test(
    //       'fileFormat',
    //       'Unsupported file format',
    //       value => value && SUPPORTED_FORMATS.includes(value.type)
    //     ),
    //   otherwise: yup.mixed(),
    // }),
    adhaar: yup.mixed().when('merchentType', {
      is: 'individual',
      then: yup
        .mixed()
        .required('Adhaar is required for individuals')
        .test(
          'fileSize',
          'File size is too large Max 350kb',
          value => value && value.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Unsupported file format',
          value => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      otherwise: yup.mixed(),
    }),
    terms: yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    guidline: yup.boolean().oneOf([true], "You must accept the Branding Guidelines"),
   
});
export default Schema;
